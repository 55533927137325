export const state = () => ({
  cart: null,
  commonError: null,
  paymentMethods: {
    creditcard: { icons: ['Visa', 'MasterCard'] },
    // postfinance: ['PostCard', 'PostFinance'],
    paypal: { icons: ['PayPal'] },
    twint: { icons: ['Twint'] },
    invoice: { icons: ['Invoice'] },
  },
})

export const actions = {
  async load({ commit }) {
    try {
      const { data } = await this.$axios.$get(`/shop/cart`, {
        params: {
          guest_id: this.$cookies.get('guest_id'),
        },
      })

      if (data.guest_id) {
        this.$cookies.set('guest_id', data.guest_id, {
          maxAge: '10080',
          path: '/',
        })
      } else {
        this.$cookies.remove('guest_id')
      }

      commit('setCart', data)
      return data
    } catch (error) {
      commit(
        'commonError',
        error && error.response ? error.response.data : error
      )
    }
  },

  async linkGuestCartToUser({ commit }) {
    const guestId = this.$cookies.get('guest_id')

    if (!guestId) {
      return
    }

    try {
      const { data } = await this.$axios.$post('/shop/cart/link-guest-cart', {
        guest_id: guestId,
      })

      this.$cookies.remove('guest_id')

      commit('setCart', data)
      // We need to check if the cart contains already bought licenses by the user
      // and if so, redirect to the shopping cart page
      if (
        data.items.some((item) => item.is_license_product) &&
        data.already_bought_test_licenses.length > 0
      ) {
        this.$router.push('/shop/cart')
      }
      return data
    } catch (error) {
      commit(
        'commonError',
        error && error.response ? error.response.data : error
      )
    }
  },

  async increaseAmount(
    { commit, dispatch, state },
    { itemIds, itemId, amount = 1, shoppingListCode = null }
  ) {
    if (!state.cart?.id) {
      await dispatch('load')
    }

    const { data } = await this.$axios.$post(
      `/shop/cart/entries/increment-amount`,
      {
        amount,
        products: itemIds || [itemId],
        guest_id: this.$cookies.get('guest_id'),
        from_shopping_list: shoppingListCode,
      }
    )

    if (data.guest_id) {
      this.$cookies.set('guest_id', data.guest_id, {
        maxAge: '10080',
        path: '/',
      })
    } else {
      this.$cookies.remove('guest_id')
    }

    commit('setCart', data)
  },

  async decreaseAmount(
    { commit, dispatch, state },
    { itemId, amount = 1, shoppingListCode = null }
  ) {
    if (!state.cart?.id) {
      await dispatch('load')
    }

    const { data } = await this.$axios.$post(
      `/shop/cart/entries/decrease-amount`,
      {
        amount,
        products: [itemId],
        guest_id: this.$cookies.get('guest_id'),
        from_shopping_list: shoppingListCode,
      }
    )

    if (data.guest_id) {
      this.$cookies.set('guest_id', data.guest_id, {
        maxAge: '10080',
        path: '/',
      })
    } else {
      this.$cookies.remove('guest_id')
    }

    commit('setCart', data)
  },

  async setAmount(
    { commit, dispatch, state },
    { itemId, amount = 1, shoppingListCode = null }
  ) {
    if (!state.cart?.id) {
      await dispatch('load')
    }

    const { data } = await this.$axios.$post(`/shop/cart/entries/set-amount`, {
      amount,
      products: [itemId],
      guest_id: this.$cookies.get('guest_id'),
      from_shopping_list: shoppingListCode,
    })

    if (data.guest_id) {
      this.$cookies.set('guest_id', data.guest_id, {
        maxAge: '10080',
        path: '/',
      })
    } else {
      this.$cookies.remove('guest_id')
    }

    commit('setCart', data)
  },

  async remove({ commit, dispatch, state }, { itemId }) {
    if (!state.cart?.id) {
      await dispatch('load')
    }

    let url = `/shop/cart/entries`

    const params = new URLSearchParams()
    params.append('products[]', itemId)
    const guestId = this.$cookies.get('guest_id')
    if (guestId) {
      params.append('guest_id', guestId)
    }

    url += `?${params.toString()}`

    const { data } = await this.$axios.$delete(url)

    if (data.guest_id) {
      this.$cookies.set('guest_id', data.guest_id, {
        maxAge: '10080',
        path: '/',
      })
    } else {
      this.$cookies.remove('guest_id')
    }

    commit('setCart', data)
  },

  async checkout({ dispatch }, options) {
    const { paymentLink } = await this.$axios.$post('/shop/checkout', options)
    return paymentLink
  },

  async setPaymentMethod({ commit }, paymentMethod) {
    const { data } = await this.$axios.$post('/shop/cart/payment-method', {
      payment_method: paymentMethod,
    })
    commit('setCart', data)
  },

  async setLicenceRedeemChoice({ commit }, { itemId, choice }) {
    const { data } = await this.$axios.$post(
      '/shop/cart/licence-redeem-choice',
      {
        itemId,
        choice,
      }
    )
    commit('setCart', data)
  },

  async setCouponPlatformChoice({ commit }, { itemId, choice }) {
    const { data } = await this.$axios.$post(
      '/shop/cart/coupon-platform-choice',
      {
        itemId,
        choice,
      }
    )
    commit('setCart', data)
  },

  async applyVoucher({ commit }, code) {
    const { data } = await this.$axios.$post(
      '/shop/cart/apply-voucher',
      {
        code,
      },
      {
        params: {
          guest_id: this.$cookies.get('guest_id'),
        },
      }
    )
    commit('setCart', data)
  },

  async removeVoucher({ commit }, code) {
    const { data } = await this.$axios.$post(
      '/shop/cart/remove-voucher',
      {
        code,
      },
      {
        params: {
          guest_id: this.$cookies.get('guest_id'),
        },
      }
    )
    commit('setCart', data)
  },
}

export const mutations = {
  setCart(state, cart) {
    state.cart = cart
  },

  commonError(state, commonError) {
    state.commonError = commonError
  },
}

export const getters = {
  itemsInCart(state) {
    return state.cart?.items ? state.cart.items.length : 0
  },

  onlyDigitalItems(state, getters) {
    const digitalItems = state.cart?.items?.filter((i) => i.is_digital).length

    return digitalItems === getters.itemsInCart
  },

  allowedPaymentMethods(state) {
    let methods = Object.entries(state.paymentMethods)
    methods = methods.filter(([key, _]) =>
      state.cart?.allowed_payment_methods.includes(key)
    )

    methods = Object.fromEntries(methods)

    return methods
  },

  productsInCart(state) {
    return state.cart?.items ?? []
  },
}
